import React from 'react';

import SEO from '../components/seo';
import Main from '../components/main/main';
import Testimonial from './../components/main/testimonial';


const IndexPage = () => (
    <>
        <SEO title='Home' />
        <Main>
            <Testimonial />
        </Main>
    </>
);

export default IndexPage;