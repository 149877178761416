import React from 'react';
import { Link } from "gatsby"

// import portrait from './../../images/me.jpeg';
// import JSONData from './../../../content/website-content.de.json';

class Testimonial extends React.Component {

    renderLink (data, index) {
        if (data.type === 'external') {
            return <a key={index} href={data.url} target="_blank" rel="noopener noreferrer" className="testimonial__link">
                    {data.text}
                </a>
        } else {
            return <Link to={data.url} key={index} className="testimonial__link">{data.text}</Link>
        }
    };

    render () {
        return (
            <div>
                nothing to see
            </div>
        )

        // return (
        //     <section className='testimonial'>
        //         <div className='testimonial__inner'>
        //         <figure className='testimonial__figure'>
        //             <img src={portrait} alt='' />
        //         </figure>
        //         <blockquote className="testimonial__quote">
        //             <p>{JSONData.testimonial.quote}</p>
        //             <footer>{JSONData.testimonial.author}</footer>
        //         </blockquote>

        //         <div className='testimonial__links'>
        //             {JSONData.testimonial.link_list.map((data, index) => {
        //                 return this.renderLink(data, index)
        //             })}
        //         </div>
        //         </div>
        //     </section>
        // )
    };
}

export default Testimonial;
